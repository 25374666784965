import { VENDOR_TRANSACTIONS, VENDOR_BALANCE } from "../endpoints";
import api from "../api";

export default {
    index(query = null) {
        let url = new URL(VENDOR_TRANSACTIONS);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    balance() {
        return api.get(VENDOR_BALANCE);
    }
}