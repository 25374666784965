<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th>Data</v-table-th>
        <v-table-th>Descriere</v-table-th>
        <v-table-th>Debit</v-table-th>
        <v-table-th>Credit</v-table-th>
      </tr>
    </v-thead>

    <v-tbody>
      <tr v-for="row in rows" :key="row.id">
        <v-table-td>
          <p class="text-gray-900 whitespace-no-wrap">
            {{ new Date(row.transaction.transaction_date).toLocaleString() }}
          </p>
        </v-table-td>
        <v-table-td>
          {{ row.transaction.description }}
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.debit) }}
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.credit) }}
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import { priceFormatter } from "@/helpers";
import VTable from "./VTable.vue";
import VTableTd from "./VTableTd.vue";
import VTableTh from "./VTableTh.vue";
import VTbody from "./VTbody.vue";
import VThead from "./VThead.vue";
export default {
  components: { VTableTd, VTable, VThead, VTableTh, VTbody },
  props: {
    rows: {
      type: Array,
    },
  },
  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>

<style></style>
