<template>
  <app-layout>
    <page-header> Tranzactii </page-header>

    <page-section>
      <page-section-header>
        Balanta {{ formatPrice(balance) }}
      </page-section-header>

      <v-card>
        <v-card-body>
          <form class="flex" @submit.prevent="index">
            <div class="sm:w-50 mr-2 md:ml-0">
              <v-label>Data </v-label>
              <v-input type="date" v-model="query.created_at" />
            </div>
            <button class="btn self-end">Filtreaza</button>
          </form>
        </v-card-body>
      </v-card>

      <v-card class="mt-2">
        <loader v-if="loading" class="my-5" />

        <template v-else>
          <template v-if="totalRows">
            <transactions-index-table :rows="rows" />
            <pagination
              @change:page="changePage($event)"
              :total="meta.total"
              :current_page="meta.current_page"
              :from="meta.from"
              :to="meta.to"
              :next_page_url="links.next"
              :prev_page_url="links.prev"
            />
          </template>
          <no-results v-else class="m-5" />
        </template>
      </v-card>
    </page-section>
  </app-layout>
</template>

<script>
import { priceFormatter } from "@/helpers";
import transactionsApi from "@/api/resources/transactionsApi";
import AppLayout from "../layout/AppLayout.vue";
import TransactionsIndexTable from "../components/TransactionsIndexTable.vue";
import VCard from "../components/VCard.vue";
import Loader from "../components/Loader.vue";
import NoResults from "../components/NoResults.vue";
import Pagination from "../components/Pagination.vue";
import VLabel from "../components/VLabel.vue";
import VInput from "../components/VInput.vue";
import PageHeader from "../components/PageHeader.vue";
import PageSection from "../components/PageSection.vue";
import PageSectionHeader from "../components/PageSectionHeader.vue";
import VCardBody from '../components/VCardBody.vue';

export default {
  components: {
    AppLayout,
    TransactionsIndexTable,
    VCard,
    Loader,
    NoResults,
    Pagination,
    VLabel,
    VInput,
    PageHeader,
    PageSection,
    PageSectionHeader,
    VCardBody,
  },

  data() {
    return {
      balance: 0,
      rows: [],
      meta: {},
      links: {},
      loading: true,
      query: {
        created_at: "",
        page: 1,
      },
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    changePage(page) {
      this.query.page = page;
      this.index();
    },

    index() {
      this.loading = true;

      transactionsApi
        .index({ ...this.query })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;

          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  created() {
    transactionsApi
      .balance()
      .then((response) => (this.balance = response.data))
      .catch((error) => console.log(error));
  },

  mounted() {
    this.index();
  },
};
</script>

<style></style>
